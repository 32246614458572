import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const ctrl = this;

    $(document).on('click', '#return_imports_index_table .highlightable', function() {
      ctrl.returnImportRowClicked($(this));
    });

    $(document).on('click', '#returnable_item_index_table .highlightable', function() {
      ctrl.returnPaymentRowClicked($(this));
    });

    $(document).on('click', '#edit_return_check', function() {
      ctrl.showEditReturnCheckModal($(this));
    });

    $(document).on('click', '#return_items_index_table .highlightable', function() {
      ctrl.returnItemRowClicked($(this));
    });

    $(document).on('click', '#rejection_button', function() {
      ctrl.handleRejection($(this));
    });
  }

  showEditReturnCheckModal(button) {
    event.preventDefault();
    if (!button.hasClass('disabled')) {
      showSpinner();
      $.getScript($('#return_item_rows .selected').data('edit-url'));
    }
  }

  linkPaymentToCheck(button) {
    event.preventDefault();
    if (!button.hasClass('disabled')) {
      showSpinner();
      $.get($('#return_imports_index_table .selected').data('link-payment-url'));
    }
  }

  handleRejection(button) {
    event.preventDefault();
    if (!button.hasClass('disabled')) {
      showSpinner();
      if ($('#return_item_rows .selected #status').val() == 'rejected') {
        $.get($('#return_item_rows .selected').data('handle-restore-url'));
      } else {
        $.get($('#return_item_rows .selected').data('handle-rejection-url'));
      }
    }
  }

  returnImportRowClicked(row) {
    if (row.hasClass('selected')) return;

    row.addClass('selected').siblings().removeClass('selected');
    showSpinner();
    $.get(row.data('return-items-url'));
    $("#return_items\\.status option:selected").prop("selected", false);
  }

  returnPaymentRowClicked(row) {
    if (row.hasClass('selected')) return;

    row.addClass('selected').siblings().removeClass('selected');
    $('#link_payment_button').enableButton();
    $('#link_payment_button').attr('href', row.data('link_payment_url'));
    $.get($('#returnable_item_index_table .selected').data('show_url'));
    $("#return_items\\.status option:selected").prop("selected", false);
  }

  returnItemRowClicked(row) {
    if (row.hasClass('selected')) return;

    row.addClass('selected').siblings().removeClass('selected');
    if (typeof row.data('edit-url') !== 'undefined') {
      $('#edit_return_check').enableButton();
    } else {
      $('#edit_return_check').disableButton();
    }

    let status = row.find('input[type="hidden"]').last().val();
    $('#rejection_button').removeClass('disabled');
    if (status == 'unmatched' || status == 'rejected') {
      if (status == 'rejected') {
        $('#rejection_button').text('Restore Return Item');
      } else {
        $('#rejection_button').text('Reject Return Item');
      }
    } else {
      $('#rejection_button').text('Reject Return Item');
      $('#rejection_button').addClass('disabled');
    }
    showSpinner();
    $.get(row.data('url'));
  }
}
